import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { 
  Box, 
  Button, 
  Container, 
  Grid, 
  Link, 
  Typography 
} from '@mui/material';

import "./styles.scss";

const PricingPage = () => {
	const location = useLocation();
  const [monthly, setMonthly] = useState(true);

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  return (
    <Box
      mb={"150px"}>
      <Container
        maxWidth="xl">
        
        <Box
          className="flex__Column"
          gap={"30px"}
          mt={"50px"}
          mb={"50px"}>
          <Typography
            sx={{ typography: { xs: "h6", sm: "h6", md: "h5", lg: "h5" }}}
            textAlign={"center"}>
            Choose a plan that works for you
          </Typography>
          <Typography
            color={"grey"}
            textAlign={"center"}>
            Streamline your financial operations with our comprehensive payment platform tailored to your business needs.
          </Typography>
        </Box>

        <section className="toggle-container">
          <div className="toggle">
            <input 
              type="radio" 
              id="monthly" 
              name="billing" 
              value="monthly" 
              checked 
              onClick={() => {
                setMonthly(true);
                console.log("asdf")
              }} />
            <label 
              for="monthly" 
              className={`${monthly === true && 'active'}`}>
              Monthly
            </label>
            <input 
              type="radio" 
              id="yearly" 
              name="billing" 
              value="yearly" 
              onClick={() => {
                setMonthly(false);
                console.log("asdf")
              }} />
            <label 
              for="yearly"
              className={`${monthly === false && 'active'}`}>
              Yearly
            </label>
          </div>
        </section>

        <Box
          className="flex__Column"
          gap={"30px"}
          mt={"50px"}
          mb={"50px"}>

          <Grid
            container
            spacing={4}>
            <Grid
              item xs={12} sm={12} md lg>
              <Box
                className="pricing__card flex__Column"
                gap={"20px"}>
                <Typography>
                  <h1>Starter</h1>
                </Typography>
                <Typography
                  color={"grey"}>
                  <p className="price">$0 <span className="per-month">per month</span></p>
                </Typography>
                <Typography
                  color={"grey"}>
                  <p><em>Limited to one user.</em></p>
                </Typography>
                <Typography
                  color={"grey"}>
                  <p>Perfect for sole proprietors and small businesses just starting out.</p>
                </Typography>
                <Typography
                  color={"grey"}>
                  <ul style={{ marginLeft: "30px" }}>
                    <li>Easy bill adding</li>
                    <li>Efficient invoice management</li>
                    <li>Global payments & collections</li>
                    <li>Multiple payment delivery speeds</li>
                    <li>Global payments in USD or local currencies</li>
                    <li>Recurring & partial payments</li>
                  </ul>
                </Typography>
              </Box>
            </Grid>
            <Grid
              item xs={12} sm={12} md lg>
              <Box
                className="pricing__card flex__Column"
                gap={"20px"}>
                <Typography>
                  <h1>Team</h1>
                </Typography>
                <Typography
                  color={"grey"}>
                  <p className="price" id="standard-price">
                    {
                      monthly
                      ? 
                        <>
                          {"$29"}&nbsp;
                          <span className="per-month">per month</span>
                        </> 
                      :
                        <>
                          {"$24.17"}&nbsp;
                          <span className="per-month">per month (billed annually)</span>
                        </> 
                    }
                  </p>
                </Typography>
                <Typography
                  color={"grey"}>
                  <p><em>Includes 3 users (Additional- $8/month per user) and 20 free ACH transfers per month.</em></p>
                </Typography>
                {/* <Typography
                  color={"grey"}>
                  <p><em></em></p>
                </Typography> */}
                <Typography
                  color={"grey"}>
                  <p>Ideal for growing businesses seeking more control and efficiency.</p>
                </Typography>
                <Typography
                  color={"grey"}>
                  <ul style={{ marginLeft: "30px" }}>
                    <li>Easy bill adding</li>
                    <li>Efficient invoice management</li>
                    <li>Global payments & collections</li>
                    <li>Multiple payment delivery speeds</li>
                    <li>Global payments in USD or local currencies</li>
                    <li>Recurring & partial payments</li>
                    {/* <li>Send 20 free ACH transfers/month</li> */}
                    <li>Batch-pay multiple bills at once</li>
                    <li>Roles, permissions, and user management</li>
                    <li>Advanced, custom approval workflows</li>
                    {/* <li>Expense management included</li> */}
                  </ul>
                </Typography>
              </Box>
            </Grid>
            <Grid
              item xs={12} sm={12} md lg>
              <Box
                className="pricing__card flex__Column"
                gap={"20px"}>
                <Typography>
                  <h1>Business</h1>
                </Typography>
                <Typography
                  color={"grey"}>
                  <p className="price" id="premium-price">
                    {
                      monthly
                      ? 
                        <>
                          {"$59"}&nbsp;
                          <span className="per-month">per month</span>
                        </> 
                      :
                        <>
                          {"$49.17"}&nbsp;
                          <span className="per-month">per month (billed annually)</span>
                        </> 
                    }
                  </p>
                </Typography>
                <Typography
                  color={"grey"}>
                  <p><em>Includes 8 users (Additional- $8/month per user) and 40 free ACH transfers per month.</em></p>
                </Typography>
                {/* <Typography
                  color={"grey"}>
                  <p><em></em></p>
                </Typography> */}
                <Typography
                  color={"grey"}>
                  <p>For established businesses needing advanced features and support.</p>
                </Typography>
                <Typography
                  color={"grey"}>
                  <ul style={{ marginLeft: "30px" }}>
                    <li>Easy bill adding</li>
                    <li>Efficient invoice management</li>
                    <li>Global payments & collections</li>
                    <li>Multiple payment delivery speeds</li>
                    <li>Global payments in USD or local currencies</li>
                    <li>Recurring & partial payments</li>
                    {/* <li>Send 40 free ACH transfers/month</li> */}
                    <li>Batch-pay multiple bills at once</li>
                    <li>Roles, permissions, and user management</li>
                    <li>Advanced, custom approval workflows</li>
                    {/* <li>Expense management included</li> */}
                    {/* <li>2-day delivery for all Standard ACH transfers</li> */}
                    {/* <li>Premium customer support</li> */}
                  </ul>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

      </Container>

      <Container
        maxWidth="lg">
        <Box
          className="flex__Column"
          gap={"30px"}
          mt={"100px"}>
          <Typography
            sx={{ typography: { xs: "h6", sm: "h6", md: "h5", lg: "h5" }}}>
            Transaction Fees
          </Typography>

          <Typography
            sx={{ typography: { xs: "subtitle1", sm: "subtitle1", md: "h6", lg: "h6" }}}>
            Pay (when you pay another business)
          </Typography>

          <Box
            className="flex__Column"
            gap={"10px"}>
            <Box
              width={"100%"}
              className="flexCenter_Row">
              <Typography
                width={"33%"}>
                <b>Payment method</b>
              </Typography>
              <Typography
                width={"33%"}>
                <b>Receiving in</b>
              </Typography>
              <Typography
                width={"33%"}>
                <b>Fee</b>
              </Typography>
            </Box>
            <Box
              width={"100%"}
              mb={"-10px"}
              className="flexCenter_Row">
              <Typography
                width={"33%"}
                color={"grey"}>
                ACH transfer	
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              3 business days
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
                $1.00 after free monthly transfer
              </Typography>
            </Box>
            <Box
              width={"100%"}
              mb={"-10px"}
              className="flexCenter_Row">
               <Typography
                width={"33%"}
                color={"grey"}>
                {/* Bank account	 */}
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              Next-day delivery
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
                $5.00
              </Typography>
            </Box>
            <Box
              width={"100%"}
              className="flexCenter_Row">
               <Typography
                width={"33%"}
                color={"grey"}>
                {/* Bank account	 */}
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              Saturday/Holiday delivery
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
                $10.00
              </Typography>
            </Box>
            {/* <Box
              width={"100%"}
              className="flexCenter_Row">
                <Typography
                width={"33%"}
                color={"grey"}>
                Card to Bank account
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              Next-day delivery
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
                2.95%
              </Typography>
            </Box> */}
            <Box
              width={"100%"}
              className="flexCenter_Row">
              <Typography
                width={"33%"}
                color={"grey"}>
                International payment from Bank account
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              USD or Foreign currency	
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
                Fair fees & low currency conversion rate applies
              </Typography>
            </Box>
          </Box>
          <Typography
            mt={"20px"}
            sx={{ typography: { xs: "subtitle1", sm: "subtitle1", md: "h6", lg: "h6" }}}>
            Get paid (when another business pays you with Lusid)
          </Typography>
          <Box
            className="flex__Column"
            gap={"10px"}>
            <Box
              width={"100%"}
              className="flexCenter_Row">
              <Typography
                width={"33%"}>
                <b>Payment method</b>
              </Typography>
              <Typography
                width={"33%"}>
                <b>Receiving in</b>
              </Typography>
              <Typography
                width={"33%"}>
                <b>Fee</b>
              </Typography>
            </Box>
            <Box
              width={"100%"}
              className="flexCenter_Row">
              <Typography
                width={"33%"}
                color={"grey"}>
                ACH transfer	
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              1 to 3 business days
              </Typography>
              <Typography
                width={"33%"}
                pr={"30px"}
                color={"grey"}>
                Free if sent by a Lusid subscriber, Otherwise $1.00 after free monthly transfers
              </Typography>
            </Box>
            {/* <Box
              width={"100%"}
              className="flexCenter_Row">
                <Typography
                width={"33%"}
                color={"grey"}>
                Card to Bank account
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              Next-day delivery
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
                2.95%
              </Typography>
            </Box> */}
            <Box
              width={"100%"}
              className="flexCenter_Row">
              <Typography
                width={"33%"}
                color={"grey"}>
                International collection to Bank account
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
	              USD or Foreign currency	
              </Typography>
              <Typography
                width={"33%"}
                color={"grey"}>
                Fair fees & low currency conversion rate applies
              </Typography>
            </Box>
          </Box>
          <Typography
            variant='caption'
            color={"grey"}>
            <i>Additional fees may apply in exception conditions like failed, cancelled, or returned transactions.</i>
          </Typography>
        </Box>
      </Container>

      {/* <Container
        maxWidth="lg">
        <Box
          className="flex__Column"
          gap={"30px"}
          mt={"100px"}>
          
          
        </Box>
      </Container> */}

      {/* <Container
        maxWidth="lg">
        <Box
          className="flex__Column"
          gap={"30px"}
          mt={"100px"}>
          <Typography
            sx={{ typography: { xs: "h6", sm: "h6", md: "h5", lg: "h5" }}}>
            Get paid faster
          </Typography>
          <Typography
            color={"grey"}>
            Offer your customers flexible payment options:
            <ul style={{ marginLeft: "40px" }}>
              <li>ACH bank transfer: $1.00 or free if sent from a Lusid subscriber</li>
              <li>Credit card payment: 2.95%</li>
              <li>International payment in USD: Free (currency conversion rate applies)</li>
            </ul>
          </Typography>
        </Box>
      </Container> */}

      <Box
        mt={"100px"}
        className="flexCenterCenterColumn"
        gap={"20px"}>
        <Typography variant='h5' textAlign={"left"} mb={"20px"}>
          Start optimizing your cash flow today!
        </Typography>
        <Link
          href={process.env.REACT_APP_CORE_WEB_URL}
          target="_blank" rel="noopener noreferrer"
          >
          <Button variant='outlined'>Get started</Button>
        </Link>
      </Box>

    </Box>
  )
}

export default PricingPage;

